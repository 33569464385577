import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video", "playButton"];
  declare readonly videoTarget: HTMLVideoElement;
  declare readonly playButtonTarget: HTMLElement;

  connect() {
    this.updatePlayButton();
  }

  togglePlay() {
    if (this.videoTarget.paused) {
      this.videoTarget.play();
    } else {
      this.videoTarget.pause();
    }
    this.updatePlayButton();
  }

  updatePlayButton() {
    if (this.videoTarget.paused) {
      this.playButtonTarget.classList.remove("hidden");
    } else {
      this.playButtonTarget.classList.add("hidden");
    }
  }
}
