import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";
import { snakeCase, keys, includes } from "es-toolkit/compat";

type TrackingData = Record<string, string>;

const EXCLUDED_TRACKING_KEYS = ["controller", "action"] as const;

export default class extends Controller {
  declare stage: string | null;
  declare role: string | null;
  declare trackingData: TrackingData;

  connect(): void {
    this.initializeTrackingData();
  }

  track(): void {
    if (this.role) {
      ahoy.track(this.role, this.trackingData);
      window.dataLayer.push({
        event: "universal_event",
        universal_event_name: this.role,
        params: { ...this.trackingData, stage: this.stage },
      });
    }
  }

  private initializeTrackingData(): void {
    this.stage = this.getMetaContent("rails_stage");
    const element = this.element as HTMLElement;
    this.trackingData = this.convertKeysToSnakeCase(element.dataset);
    this.role = element.role ?? null;
  }

  private getMetaContent(name: string): string | null {
    return (
      (document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement)
        ?.content ?? null
    );
  }

  private convertKeysToSnakeCase(obj: DOMStringMap): TrackingData {
    return keys(obj).reduce<TrackingData>((acc, key) => {
      if (includes(EXCLUDED_TRACKING_KEYS, key)) {
        return acc;
      }

      const snakeCaseKey = snakeCase(key);
      if (obj[key]) {
        acc[snakeCaseKey] = obj[key];
      }
      return acc;
    }, {});
  }
}
